import React from "react"
import { useAppContext } from "../../../../hooks/appContext"
import { normalizeResource } from "../../../../routes/util"
import {
    colorClass as colorClassObj,
    mergeClasses,
} from "../../../../utils/css"
import { ButtonColor, ButtonSize } from "../Button"
import "./Link.scss"

interface CommonLinkProps extends React.ComponentPropsWithRef<"a"> {
    /**
     * A valid color class to apply to the element. Defaults to `hrefColor`.
     */
    colorClass?: keyof typeof colorClassObj
    /**
     * The href to pass through.
     */
    href: HTMLAnchorElement["href"]
    /**
     * A variant stying to provide, valid options are "link" (a default, styled link) and "button"
     * (styled <a> tag that _looks_ like a <Button> component, but acts like a link. Useful for, say, a CTA
     * that takes you to another page instead of performing some kind of action.)
     */
    variant?: "link" | "button"
}

interface LinkProps extends CommonLinkProps {
    variant?: "link"
    color?: undefined
    size?: undefined
}

interface ButtonLinkProps extends CommonLinkProps {
    variant: "button"
    /**
     * The background color of the button, optional, by default is our brand orange color.
     */
    color?: ButtonColor
    /**
     * The size of the button. Defaults to small
     */
    size?: ButtonSize
    /**
     * Whether the button is disabled.
     */
    disabled?: boolean
}

function LinkComponent(
    props: LinkProps | ButtonLinkProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
): JSX.Element {
    const {
        color,
        size,
        colorClass,
        variant,
        children,
        className,
        href,
        ...restProps
    } = props
    const classList = []

    if (variant === "button") {
        classList.push(
            "Button",
            color ?? ButtonColor.Orange,
            size ?? ButtonSize.Small,
        )
        if (props.disabled) {
            classList.push("Button-disabled")
        }
    } else {
        classList.push("link", colorClass ?? colorClassObj.hrefColor)
    }

    const languageCode = useAppContext().context.language_code

    return (
        <a
            className={mergeClasses(classList, className)}
            href={normalizeResource(languageCode, href)}
            {...restProps}
            ref={ref}
        >
            {children}
        </a>
    )
}

/**
 * A standard link component. Renders an `<a>` tag and can be used as such. Using the `variant="button"` prop
 * will switch the styling of the rendered anchor to match that of the `<Button>` component, and exposes the
 * same `size` and `color` props as it.
 */
export const Link = React.forwardRef<
    HTMLAnchorElement,
    LinkProps | ButtonLinkProps
>(LinkComponent)
