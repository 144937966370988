import React from "react"
import "./Typography.scss"
import { Sizes, Weights } from "../../../../interfaces/sizing"
import { colorClass, mergeClasses } from "../../../../utils/css"

export interface TypographyProps
    extends React.ComponentPropsWithRef<React.ElementType<any>> {
    /**
     * Defines the font size and line height
     */
    size?: keyof typeof Sizes
    /**
     * Controls if the font is bold or normal weight
     */
    weight?: keyof typeof Weights
    /**
     * By default, the typography component emits a div. Passing another element here allows you to change this.
     * For example, `<Typography component="p">Test</Typography>` will render as `<p>Test</p>` instead.
     */
    component?: React.ElementType<any>
    /**
     * A color class to add for themeing purposes. Defaults to `textColor` for appropriate dark mode styling.
     * Please see `colorClass` at `utils/colorClasses` for the full range of options.
     */
    color?: (typeof colorClass)[keyof typeof colorClass]
    /**
     * Any additional classnames you want to provide.
     */
    className?: string
    /**
     * Typography does not expect you to pass the raw text into a prop, instead, pass along children.
     * This allows you to use links/spans/etc inside of the component.
     */
    children?: React.ReactNode
}

/**
 * The **Typography** component allows you to use the set of fonts from our design system easily,
 * and should be your first choice for generic text display.
 */
export const Typography = React.forwardRef<
    React.ElementType<any>,
    TypographyProps
>(
    (
        {
            size: variant = Sizes.md,
            weight = Weights.normal,
            component = "div",
            color = colorClass.textColor,
            children,
            className,
            ...props
        },
        ref,
    ) => {
        const Component = component
        const classList = [`type--${variant}`, `type--${weight}`, `${color}`]

        return (
            <Component
                className={mergeClasses(classList, className)}
                {...props}
                ref={ref}
            >
                {children}
            </Component>
        )
    },
)
