export const colorClass = {
    textColor: "textColor", // #494949
    bgColor: "bgColor", // #FFFFFF
    brandColor: "brandColor", // #F47321
    brandBlue: "brandBlue", // #306A91
    tabSectionBorder: "tabSectionBorder", // 1px solid #8bb3da
    tabBorder: "tabBorder", // 1px solid #8bb3da;
    tabActiveBorder: "tabActiveBorder", // 1px solid #ffffff;
    tabInactiveBgColor: "tabInactiveBgColor", // #dde9f5
    tabInactiveColor: "tabInactiveColor", // #5e81a4;
    tabActiveBgColor: "tabActiveBgColor", // #FFFFFF
    tabActiveColor: "tabActiveColor", // #dc5500
    chatAreaTabColor: "chatAreaTabColor", // #4c4c4c
    defaultColor: "defaultColor", // #494949
    defaultTooltipColor: "defaultTooltipColor", // #222222
    disabledTooltipColor: "disabledTooltipColor",
    roomSubjectToolTipColor: "roomSubjectToolTipColor", // #494949
    hrefColor: "hrefColor", // #0A5B83
    supportSuccess: "supportSuccess",
    supportError: "supportError",
    tagFontColor: "tagFontColor", // #FFFFFF
    tagExhibColor: "tagExhibColor", // #6D85B5
    tagNewColor: "tagNewColor",
    tagLineColor: "tagLineColor", // #0b5d81
    tagOfflineColor: "tagOfflineColor", // 4F4F4F
    tagPrivateGroupColor: "tagPrivateGroupColor", // #B60A42
    tagGamingColor: "tagGamingColor", // #7F26B1
    camBgColor: "camBgColor", // #F0F1F1
    camBorderColor: "camBorderColor", // #ACACAC
    camSubjectColor: "camSubjectColor", // #000000
    camSubjectTagColor: "camSubjectTagColor", // #0A5A83
    camAltTextColor: "camAltTextColor", // #575757
    camHrefColor: "camHrefColor", // #0A5A83
    textBodySecondary: "textBodySecondary",
    nextCamBgColor: "nextCamBgColor", // #FCEADB
    navigationAlt2BgImage: "navigationAlt2BgImage", // url("../../../tsdefaultassets/gender_tab_bg.gif") repeat-x;
    navigationAlt2BgColor: "navigationAlt2BgColor", // #ffffff
    dismissibleMessageColor: "dismissibleMessageColor", // #494949
    userInfoDropdownBgColor: "userInfoDropdownBgColor", // #3C87BA
    userInfoDropdownHighlightColor: "userInfoDropdownHighlightColor", // #F47321
    userInfoDropdownTextColor: "userInfoDropdownTextColor", // #ffffff
}
