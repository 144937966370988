export const enum Sizes {
    xspx = "xspx",
    smpx = "smpx",
    mdpx = "mdpx",
    lgpx = "lgpx",
    xlpx = "xlpx",
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl",
}

export const enum Weights {
    normal = "normal",
    bold = "bold",
    medium = "medium",
}
