import { generatePath, Params } from "react-router-dom"

// Note:  Do not use this in a Link - this should only be used outside of
export function generateApiPath(
    languageCode: string,
    basename: string,
    path: string,
    params?: Params<string>,
): string {
    const persistTrailingSlash = path.endsWith("/")
    const expandedPath = normalizeResource(
        languageCode,
        `/api/ts/${basename}/${generatePath(path, params)}`,
    )

    return persistTrailingSlash && !expandedPath.endsWith("/")
        ? `${expandedPath}/`
        : expandedPath
}

export function normalizeResource(
    languageCode: string,
    resource: string,
): string {
    // TODO uncomment after backend changes merge
    // if (resource.match(/^[a-zA-Z]+:\/\//) !== null) {
    //     return resource
    // }
    // return languageCode !== "en" ? `/${languageCode}${resource}` : resource
    return resource
}
