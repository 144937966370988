import type { PropsWithChildren } from "react"
import type React from "react"
import { createContext, useContext, useMemo } from "react"
import { parseExperimentData } from "../interfaces/experimentContext"
import { parseFeatureFlagData } from "../interfaces/featureFlagContext"
import type { IAppContext } from "../interfaces/appContext"
import type {
    IExperimentData,
    IExperimentInput,
} from "../interfaces/experimentContext"
import type { IFeatureFlagData } from "../interfaces/featureFlagContext"

interface IAppContextInput<Context extends IAppContext> {
    $reactAppContext: Context
    experiments?: IExperimentInput
    active_feature_flags?: string
}

type IAppContextData<Context extends IAppContext> = {
    context: Context
    experiments: IExperimentData
    flags: IFeatureFlagData
}

const AppContext = createContext<IAppContextData<IAppContext>>(
    undefined as unknown as IAppContextData<IAppContext>,
)

export function AppContextProvider<Context extends IAppContext>({
    children,
    context,
}: PropsWithChildren<{ context: IAppContextInput<Context> }>) {
    const contextValue = useMemo(() => {
        return {
            context: context.$reactAppContext,
            experiments: parseExperimentData(context.experiments),
            flags: parseFeatureFlagData(context.active_feature_flags),
        }
    }, [
        context.$reactAppContext,
        context.experiments,
        context.active_feature_flags,
    ])
    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )
}

export function useAppContext<
    Context extends IAppContext,
>(): IAppContextData<Context> {
    const context = useContext<IAppContextData<Context>>(
        AppContext as unknown as React.Context<IAppContextData<Context>>,
    )
    if (!context) {
        throw new Error("useAppContext must be used under AppContextProvider")
    }
    return context
}

export type Experiment = {
    name: string
    active: boolean
    eligible: boolean
}

export function useExperiment(name: string): Experiment {
    const { experiments } = useAppContext()
    return {
        name: name,
        active: experiments.active.has(name),
        eligible: experiments.eligible.has(name),
    }
}

export type MultiSidedExperiment = {
    names: string[]
    namespace: string
    active: boolean
    eligible: boolean
    isControlGroup: boolean
    activeSide: string | undefined
}

export function useMultiSidedExperiment(
    names: string[],
    namespace: string,
): MultiSidedExperiment {
    const { experiments } = useAppContext()
    let active = false
    let eligible = false
    let activeSide

    for (const name of names) {
        if (experiments.eligible.has(name)) {
            eligible = true
            break
        }
    }
    for (const name of names) {
        if (experiments.active.has(name)) {
            active = true
            activeSide = name
            break
        }
    }

    return {
        names: names,
        namespace: namespace,
        active: active,
        eligible: eligible,
        activeSide: activeSide,
        isControlGroup: eligible && !active,
    }
}

export type FeatureFlag = {
    name: string
    active: boolean
}

export function useFeatureFlag(name: string): FeatureFlag {
    const { flags } = useAppContext()
    return {
        name: name,
        active: flags.active.has(name),
    }
}

export function featureFlagIsActive(name: string): boolean {
    const flagRegExp = new RegExp(`\\b${name}\\b`)
    return flagRegExp.test(window["active_feature_flags"])
}
