export interface IExperimentData {
    active: Set<string>
    eligible: Set<string>
}

export interface IExperimentInput {
    active: string
    eligible: string
}

export function parseExperimentData(data?: IExperimentInput): IExperimentData {
    return {
        active: data ? new Set(data.active.split(",")) : new Set<string>(),
        eligible: data ? new Set(data.eligible.split(",")) : new Set<string>(),
    }
}
